import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import News01 from "../assets/newsArticles/0_1.jpg";
import News02 from "../assets/newsArticles/1_1.jpg";
import News03 from "../assets/newsArticles/3_1.jpg";
import News04 from "../assets/newsArticles/5_1.jpg";

import News1 from "../assets/newsArticles/0_2.jpg";
import News2 from "../assets/newsArticles/0_3.jpg";
import News3 from "../assets/newsArticles/0_4.jpg";
import News4 from "../assets/newsArticles/0_5.jpg";

import News5 from "../assets/newsArticles/1_2.jpg";
import News6 from "../assets/newsArticles/1_3.jpg";
import News7 from "../assets/newsArticles/1_4.jpg";
import News8 from "../assets/newsArticles/1_5.jpg";

import News9 from "../assets/newsArticles/3_2.jpg";
import News10 from "../assets/newsArticles/3_3.jpg";
import News11 from "../assets/newsArticles/3_4.jpg";

import News12 from "../assets/newsArticles/5_2.jpg";
import News13 from "../assets/newsArticles/5_3.jpg";
import News14 from "../assets/newsArticles/5_4.jpg";

import News15 from "../assets/newsArticles/7_1.jpg";
import News16 from "../assets/newsArticles/7_2.jpg";
import News17 from "../assets/newsArticles/7_3.jpg";

import News18 from "../assets/newsArticles/8_1.jpg";
import News19 from "../assets/newsArticles/8_2.jpg";

import News20 from "../assets/newsArticles/9_1.jpg";

import News21 from "../assets/newsArticles/10_1.jpg";
import News22 from "../assets/newsArticles/10_2.jpg";
import News23 from "../assets/newsArticles/10_3.jpg";
import News24 from "../assets/newsArticles/10_4.jpg";

import MoreArticles04 from "../assets/newsArticles/6_2.jpg";
import MoreArticles05 from "../assets/newsArticles/2.jpg";
import MoreArticles06 from "../assets/newsArticles/4.jpg";
import YouTube from "./YouTube";
import BrowseGallery from "../hooks/BrowseGallery";
import { useSwipeable } from "react-swipeable";
import Layout from "../layout/Layout";
import Handlers from "../hooks/Handlers";
import i18next from "../../services/i18next";

function Press() {
  const { t } = useTranslation();

  const newsImages = [News01, News1, News2, News3, News4];
  const newsImages2 = [News02, News5, News6, News7, News8];
  const newsImages3 = [News03, News9, News10, News11];
  const newsImages4 = [News04, News12, News13, News14];
  const newsImages5 = [News15, News16, News17];
  const newsImages6 = [News18, News19];
  const newsImages7 = [News20];
  const newsImages8 = [News21, News22, News23, News24];

  const MoreArticles = [MoreArticles04, MoreArticles05, MoreArticles06];

  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [currentImageArray, setCurrentImageArray] = useState([]);

  const imageRef = useRef(null);
  const popupRef = useRef(null);

  const scrollRef = useRef(null);
  const handleScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18next.changeLanguage(storedLanguage);
    }
  }, []);

  const openPopup = (imageIndex, imageArray) => {
    setCurrentImageIndex(imageIndex);
    setCurrentImageArray(imageArray);
    setShowPopup(true);
    setShowCloseButton(true);
  };

  const closePopup = () => {
    setCurrentImageIndex(null);
    setShowPopup(false);
    setShowCloseButton(false);
  };

  const prevImage = () => {
    if (currentImageIndex === 0) {
      return;
    }
    setCurrentImageIndex((prevIndex) => prevIndex - 1);
  };

  const nextImage = () => {
    if (currentImageIndex === currentImageArray.length - 1) {
      return;
    }
    setCurrentImageIndex((prevIndex) => prevIndex + 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextImage,
    onSwipedRight: prevImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Layout>
      <div>
        {/* First Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 bg-neutral-100 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-around items-center text-gray-700">
            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle1")}
              </h1>
              <span className="text-lg">{t("pressText1")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages)}
              >
                {t("readMore")}
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </span>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img
                src={News01}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0"
                onClick={() => openPopup(0, newsImages)}
              />
            </div>
          </div>
        </div>

        {/* Second Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around text-gray-700">
            <div className="md:flex justify-start">
              <img
                src={News02}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0 hidden lg:block"
                onClick={() => openPopup(0, newsImages2)}
              />
            </div>

            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle2")}
              </h1>
              <span className="text-lg">{t("pressText2")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages2)}
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
                {t("readMore")}
              </span>

              {/* Mobile Article II */}

              <div className="flex justify-center">
                <img
                  src={News02}
                  alt=""
                  className="lg:hidden md:h-[400px] h-[400px] mt-4 lg:mt-0 object-contain cursor-pointer md:mt-4"
                  onClick={() => openPopup(0, newsImages2)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Third Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28 bg-neutral-100">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around text-gray-700">
            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle3")}
              </h1>
              <span className="text-lg">{t("pressText3")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages3)}
              >
                {t("readMore")}{" "}
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </span>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img
                src={News03}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0"
                onClick={() => openPopup(0, newsImages3)}
              />
            </div>
          </div>
        </div>

        {/* Fourth Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around text-gray-700">
            <div className="md:flex justify-start">
              <img
                src={News04}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0 hidden lg:block"
                onClick={() => openPopup(0, newsImages4)}
              />
            </div>
            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle4")}
              </h1>
              <span className="text-lg">{t("pressText4")}</span>
              <br />
              <span className="text-lg italic">{t("pressText4.2")}</span>
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages4)}
              >
                <ion-icon name="arrow-back-outline"></ion-icon> {t("readMore")}
              </span>

              {/* Mobile Article IV */}

              <div className="flex justify-center">
                <img
                  src={News04}
                  alt=""
                  className="lg:hidden md:h-[400px] h-[400px] object-contain cursor-pointer mt-0 md:mt-4 lg:mt-0"
                  onClick={() => openPopup(0, newsImages4)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Fifth Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 bg-neutral-100 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-around items-center text-gray-700">
            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle6")}
              </h1>
              <span className="text-lg">{t("pressText6")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages6)}
              >
                {t("readMore")}
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </span>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img
                src={News18}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0"
                onClick={() => openPopup(0, newsImages6)}
              />
            </div>
          </div>
        </div>

        {/* Sixth Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around text-gray-700">
            <div className="md:flex justify-start">
              <img
                src={News20}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0 hidden lg:block"
                onClick={() => openPopup(0, newsImages7)}
              />
            </div>

            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle7")}
              </h1>
              <span className="text-lg">{t("pressText7")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages7)}
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
                {t("readMore")}
              </span>

              {/* Mobile Article II */}

              <div className="flex justify-center">
                <img
                  src={News20}
                  alt=""
                  className="lg:hidden md:h-[400px] h-[400px] mt-4 lg:mt-0 object-contain cursor-pointer md:mt-4"
                  onClick={() => openPopup(0, newsImages7)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Seventh Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 bg-neutral-100 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-around items-center text-gray-700">
            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle8")}
              </h1>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages8)}
              >
                {t("readMore")}
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </span>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img
                src={News21}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0"
                onClick={() => openPopup(0, newsImages8)}
              />
            </div>
          </div>
        </div>

        {/* Eight Article */}

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around text-gray-700">
            <div className="md:flex justify-start">
              <img
                src={News15}
                alt="News Article - The art of watercolor"
                className="object-contain cursor-pointer xl:h-[600px] md:h-[400px] h-[400px] mt-4 lg:mt-0 hidden lg:block"
                onClick={() => openPopup(0, newsImages5)}
              />
            </div>

            <div>
              <h1 className="xl:text-7xl md:text-5xl text-3xl lg:pb-10 pb-5">
                {t("pressTitle5")}
              </h1>
              <span className="text-lg">{t("pressText5")}</span>
              <br />
              <br />
              <br />
              <span
                className="cursor-pointer text-lg"
                onClick={() => openPopup(0, newsImages5)}
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
                {t("readMore")}
              </span>

              {/* Mobile Article II */}

              <div className="flex justify-center">
                <img
                  src={News15}
                  alt=""
                  className="lg:hidden md:h-[400px] h-[400px] mt-4 lg:mt-0 object-contain cursor-pointer md:mt-4"
                  onClick={() => openPopup(0, newsImages5)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 px-10 lg:px-20 md:px-20 xl:px-40 p-10 items-center pt-28 bg-neutral-100">

          {/* More Articles */}
          <div>
            <div className="flex justify-start pr-10 pb-4 text-gray-700  pt-10">
              <p className="text-sm md:text-xl border-b-[0.5px] border-gray-600">
                {t("moreArticles1")}
                <span
                  className="cursor-pointer font-semibold"
                  onClick={() => openPopup(0, MoreArticles)}
                >
                  {t("moreArticles2")}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-10 md:px-4 px-4 py-20 text-gray-700">
        <p className="text-center text-3xl pb-14">{t("ytInterviews")}</p>
        <YouTube />
      </div>

      <div className="bg-neutral-100">
        <BrowseGallery />
      </div>

      {showPopup && currentImageIndex !== null && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={closePopup}
          {...handlers}
        >
          <div className="relative" ref={popupRef}>
            <img
              src={currentImageArray[currentImageIndex]}
              alt=""
              className="popup-image object-contain cursor-pointer"
              style={{ height: "700px" }}
              ref={imageRef}
              onClick={(e) => e.stopPropagation()}
            />

            <button
              className="absolute top-1/2 md:-left-8 sm: left-2 transform -translate-y-1/2 text-white text-2xl z-10"
              onClick={(e) => {
                e.stopPropagation();
                prevImage();
              }}
              disabled={currentImageIndex === 0}
            >
              <ion-icon name="caret-back-outline"></ion-icon>
            </button>

            <button
              className="absolute top-1/2 md:-right-8 sm: right-2 transform -translate-y-1/2 text-white text-2xl z-10"
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
              disabled={currentImageIndex === currentImageArray.length - 1}
            >
              <ion-icon name="caret-forward-outline"></ion-icon>
            </button>

            <button
              className="absolute -top-1 right-2 text-gray-600 text-2xl"
              onClick={closePopup}
            >
              &times;
            </button>
            <Handlers
              handleClosePopup={closePopup}
              prevImage={prevImage}
              nextImage={nextImage}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Press;
