import React from "react";

function YouTube() {
  return (
    <>
      <div class="flex flex-wrap justify-around lg:px-0 md:px-8 sm: px-0 gap-10 items-center">
        <div>
          <iframe 
            className="rounded-xl w-[310px]" 
            src="https://www.youtube.com/embed/W5cnwbaF12I?si=emGmBP9RkUyRqkup" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        </div>
        <div>
          <iframe 
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/_MKdGovIjTk?si=VSilOSOOZ9ZaGWhc" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        </div>
        <div>
          <iframe 
            className="rounded-xl w-[310px]" 
            src="https://www.youtube.com/embed/CzXjP2WolbI?si=KQ1yd3QlIP_aCwNl" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        </div>
        <div>
          <iframe 
            className="rounded-xl w-[310px]" 
            src="https://www.youtube.com/embed/vQqSGX_L1BI?si=h-qzvexBRuzQyJ2m" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/JmKDjzLruJE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/rivqlaKCzQ4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/92c2hU0W0Z0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/ffiGpNYOJ6w"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/euhOpXh1tZ0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/HnpjIiXH9CQ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/qVzgonnKYGg"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            className="rounded-xl w-[310px]"
            src="https://www.youtube.com/embed/0-5YmEaWBXM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default YouTube;
