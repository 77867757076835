import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import Handlers from "./Handlers";
import { useTranslation } from "react-i18next";
import i18next from "../../services/i18next";

function ShowPopup({
  showPopup,
  currentImage,
  handleClosePopup,
  handleSendRequest,
  handlePopupClick,
  currentImageArray,
}) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const currentIndex = currentImageArray?.findIndex((img) => {
      return img.url === currentImage?.img;
    });
    setCurrentImageIndex(currentIndex);
  }, [currentImage, currentImageArray]);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === currentImageArray.length - 1 ? prevIndex : prevIndex + 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextImage,
    onSwipedRight: prevImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!showPopup || !currentImage || !currentImageArray) {
    return null;
  }

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen bg-white lg:bg-black lg:bg-opacity-75 flex justify-center items-center z-50"
      onClick={handlePopupClick}
      {...handlers}
    >
      <div className="max-h-3xl relative">
        {currentImage.metadata["sold"] === "true" && (
          <div className="absolute top-0 left-0 bg-white lg:bg-neutral-50 px-4 border-t border-r border-gray-200">
            <p className="text-[20px]">SOLD</p>
          </div>
        )}
        <div className="flex flex-col lg:flex-row">
          <img
            src={currentImageArray[currentImageIndex]?.url}
            alt="Popup Image"
            className="popup-image object-contain cursor-pointer"
            style={{ height: "700px" }}
          />
          <div className="popup-image flex lg:flex-col justify-between lg:justify-around bg-white lg:bg-neutral-50 w-full lg:w-[200px]">
            <div className=" p-2">
              <p className="popup-image text-[17px]">
                {language === "eng" || language === "en"
                  ? currentImageArray[currentImageIndex]?.metadata["title"]
                  : decodeURIComponent(
                      currentImageArray[currentImageIndex]?.metadata["titlesrb"]
                    )}
              </p>
              <p className="popup-image text-[12px] text-gray-500">
                {t("dimension")}{" "}
                {currentImageArray[currentImageIndex]?.metadata["x_dim"]} x{" "}
                {currentImageArray[currentImageIndex]?.metadata["y_dim"]}{" "}
                {t("cm")}
              </p>
              <p className="popup-image text-[12px] text-gray-500">
                {t("technique")}
              </p>
            </div>
            <button
              className="popup-image lg:mx-auto m-1 h-8 border-gray-600 rounded px-2 text-[25px] hover:shadow-md hover:bg-neutral-50 transition-all duration-300 ease-in"
              onClick={() =>
                handleSendRequest(currentImageArray[currentImageIndex])
              }
            >
              {/* SEND REQUEST */}
              <ion-icon name="mail-outline"></ion-icon>
            </button>
          </div>
        </div>
        <button
          className="absolute -top-10 lg:top-1 right-4 text-black text-3xl"
          onClick={handleClosePopup}
        >
          &times;
        </button>
        <button
          className="absolute top-1/2 -left-8 transform -translate-y-1/2 text-white text-3xl z-10"
          onClick={(e) => {
            e.stopPropagation();
            prevImage();
          }}
          disabled={currentImageIndex === 0}
        >
          <ion-icon name="caret-back-outline"></ion-icon>
        </button>
        <button
          className="absolute top-1/2 -right-8 transform -translate-y-1/2 text-white text-3xl z-10"
          onClick={(e) => {
            e.stopPropagation();
            nextImage();
          }}
          disabled={currentImageIndex === currentImageArray.length - 1}
        >
          <ion-icon name="caret-forward-outline"></ion-icon>
        </button>
        {showPopup && (
          <Handlers
            handleClosePopup={handleClosePopup}
            prevImage={prevImage}
            nextImage={nextImage}
          />
        )}
      </div>
    </div>
  );
}

export default ShowPopup;
