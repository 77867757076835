import bcrypt from "bcryptjs";
const salt = bcrypt.genSaltSync(10)
const hashedUserName = "$2a$10$K0gybnlqYfSBSrzI/hchPeYgSe1p9jNsc4doWCHghIx52kkhM76Em"
const hashedPassword = "$2a$10$T1ymnyI8YSx3SWrbVcj8huTyZSu5foWTuQd.afcyvgSeDW0UO2NP2";
export const authenticateUser = async (username, password) => {
    const doesPasswordMatch = bcrypt.compareSync(password, hashedPassword);
    const doesUsernameMatch = bcrypt.compareSync(username, hashedUserName);
    if (doesUsernameMatch && doesPasswordMatch) {
        return true;
    }
    return false;
};
