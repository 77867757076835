import React, { useState } from "react";
import { uploadImage } from "../../helper_functions/aws_helper_functions";
import Filter from "../gallery/Filter";

function AddPictures() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [addNewPicture, setAddNewPicture] = useState(false);
  const [pictureName, setPictureName] = useState("");
  const [naslovSlike, setNaslovSlike] = useState("");
  const [dimX, setDimX] = useState("");
  const [dimY, setDimY] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    setPictureName(event.target.value);
  };

  const handleNaslovSlikeChange = (event) => {
    setNaslovSlike(event.target.value);
  };

  const handleDimXChange = (event) => {
    setDimX(event.target.value);
  };

  const handleDimYChange = (event) => {
    setDimY(event.target.value);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      console.log("Please select an image to upload.");
      return;
    }
    uploadImage(selectedFile, pictureName, encodeURIComponent(naslovSlike), dimX, dimY).then(() => {
      setAddNewPicture(!addNewPicture);
      setDimX("");
      setDimY("");
      setPictureName("");
      setNaslovSlike("");
      setSelectedFile(null);
    });
  };

  return (
    <div>
      <h1 className="text-2xl text-center py-4">Gallery Manager</h1>
      <div className="w-1/3 bg-gray-100 flex flex-col justify-center items-center mx-auto rounded-lg">
        <input
          type="file"
          name="file"
          id="file"
          className="-mr-24 p-2 file:uppercase file:rounded-lg file:border-gray-600 hover:file:shadow-md file:cursor-pointer"
          onChange={handleFileChange}
        />
        <label htmlFor="naslov" className="text-gray-500">
          NASLOV:
        </label>
        <input
          id="naslov"
          type="text"
          value={naslovSlike}
          onChange={handleNaslovSlikeChange}
          placeholder="Naslov slike..."
          className="border border-gray-400 px-2 rounded-lg w-[80%]"
        />
        <label htmlFor="title" className="text-gray-500">
          TITLE:
        </label>
        <input
          id="title"
          type="text"
          value={pictureName}
          onChange={handleNameChange}
          placeholder="Picture title..."
          className="border border-gray-400 px-2 rounded-lg w-[80%]"
        />
        <label htmlFor="" className="text-gray-500">
          DIMENSION:
        </label>
        <input
          id="x_dim"
          type="number"
          value={dimX}
          onChange={handleDimXChange}
          className="border border-gray-400 mb-2 px-2 rounded-lg text-center"
          placeholder="X dimension cm"
        />
        <input
          id="y_dim"
          type="number"
          value={dimY}
          onChange={handleDimYChange}
          className="border border-gray-400 mb-2 px-2 rounded-lg text-center"
          placeholder="Y dimension cm"
        />

        <button
          className="border border-gray-600 rounded-lg px-4 my-2 hover:shadow-md"
          onClick={handleUpload}
        >
          UPLOAD
        </button>
      </div>
      <div className="flex flex-col items-center py-6">
        <Filter isAdmin={true} addedNewPicture={addNewPicture} />
      </div>
    </div>
  );
}

export default AddPictures;
