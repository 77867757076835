import React, { useState } from "react";
import loginImg from "../assets/loginPicture.jpeg";
import { authenticateUser } from "../../helper_functions/authService";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Admin({ authenticated }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [plainPassword, setPlainPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    authenticateUser(username, plainPassword).then((response) => {
      authenticated(response);
      navigate("/addPictures");
    });
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
        <div className="hidden sm:block">
          <img
            src={loginImg}
            alt="Log In Picture"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="bg-gray-800 flex flex-col justify-center">
          <form
            onSubmit={handleLogin}
            className="max-w-[400px] w-full mx-auto bg-gray-900 p-8 px-8 rounded-lg"
          >
            <h2 className="text-4xl dark:text-white font-bold text-center">
              LOG IN
            </h2>
            {error && <p className="text-red-500">{error}</p>}
            <div className="flex flex-col text-gray-400 py-2">
              <label>User Name:</label>
              <input
                type="text"
                name="username"
                id="username"
                className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="flex flex-col text-gray-400 py-2">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                id="password"
                className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                value={plainPassword}
                onChange={(e) => setPlainPassword(e.target.value)}
              />
            </div>
            <br />
            <br />
            <button
              type="submit"
              className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg"
            >
              LOG IN
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Admin;
